/**
 * Import all polyfills.
 */
import 'url-search-params-polyfill';
import 'custom-event-polyfill';

/**
 * Import all required libraries.
 */
import Environment from '@flashpointbv/solar-ui-environment';
import Vue from 'vue';
import Vuex from 'vuex';
import ServiceContainer from '@flashpointbv/solar-service-container';
import VueI18n from 'vue-i18n';
import {UICoreSticky, UICoreOffCanvas, UICoreBlazeSlider, UICoreFormValidator} from '@flashpointbv/solar-ui-core';
import {SolarDataLayer} from '@flashpointbv/solar-datalayer/dist';
import BlazeSlider from '@flashpointbv/blaze-slider/dist/blaze-slider.esm';
import 'lazysizes';
import UniqueId from 'vue-unique-id';

window.BlazeSlider = BlazeSlider;

new UICoreSticky().sticky();
new UICoreOffCanvas().offCanvas(true);
new UICoreBlazeSlider();
new UICoreFormValidator();

Vue.use(VueI18n);
Vue.use(Vuex);
Vue.use(UniqueId);

SolarDataLayer.init({
    item_id: 'sku',
    item_brand: 'brand',
    item_variant: 'variant',
});

/**
 * Set important environment variables
 */
Environment().set('locale', window.Locale);
Environment().set('locale_short', window.Locale.substr(0, 2).toLowerCase());
for (let key in window.Config) Environment().set(key, window.Config[key]);

/*
* Create store instance and register the store modules
 */
import {CatalogCategory, CatalogProduct, CatalogBundleProduct, CatalogGroupedProduct, CatalogConfigurableProduct} from '@flashpointbv/solar-ui-catalog';
import {CheckoutGlobal, CheckoutPayment, CheckoutTotals, CheckoutAddress, CheckoutShipping} from '../js/stores/checkout';
import Quote from '../js/stores/quote';
import GlobalData from '../js/stores/global';
import ProductItemsCompare from '../js/stores/compare/stores/compare';
import {FindStores, StoreLocator} from '../js/stores/stores/';
import CustomerAddressAutocomplete from '../../../../../vendor/flashpoint/solar-postcode-api/resources/stores/address/autocomplete';
import ProductServices from './stores/services';
import CatalogPageBuilder from '../../../../../vendor/happy-horizon/page-builder-tweakwise-landing-pages/resources/resources/assets/js/stores/page-builder';
import CatalogGuidedSelling from '../../../../../vendor/happy-horizon/tweakwise-guided-selling/resources/resources/assets/js/stores/guided-selling';
import CustomerWishlist from '../../../../../vendor/flashpoint/solar-wishlist/resources/resources/assets/js/stores/wishlist';

const store = new Vuex.Store({
    strict: false,
});

store.registerModule('GlobalData', GlobalData);
store.registerModule('CatalogCategory', CatalogCategory);
store.registerModule('CatalogPageBuilder', CatalogPageBuilder);
store.registerModule('CatalogGuidedSelling', CatalogGuidedSelling);

store.registerModule('CatalogProduct', CatalogProduct);
store.registerModule('CatalogBundleProduct', CatalogBundleProduct);
store.registerModule('CatalogGroupedProduct', CatalogGroupedProduct);
store.registerModule('CatalogConfigurableProduct', CatalogConfigurableProduct);
store.registerModule('ProductItemsCompare', ProductItemsCompare);
store.registerModule('FindStores', FindStores);
store.registerModule('StoreLocator', StoreLocator);
store.registerModule('ProductServices', ProductServices);

store.registerModule('Quote', Quote);
store.registerModule('CheckoutGlobal', CheckoutGlobal);
store.registerModule('CheckoutTotals', CheckoutTotals);
store.registerModule('CheckoutPayment', CheckoutPayment);
store.registerModule('CheckoutAddress', CheckoutAddress);
store.registerModule('CheckoutShipping', CheckoutShipping);

store.registerModule('CustomerWishlist', CustomerWishlist);
store.registerModule('CustomerAddressAutocomplete', CustomerAddressAutocomplete);

/**
 * Require application scripts.
 */
require('./prototypes');
require('./core');

/**
 * Import all required styles.
 */
import '../scss/app.scss';

/**
 * Register Vue components.
 */
ServiceContainer().addComponents([
    {key: 'saldo-check', concrete: () => import('./components/default/SaldoCheck.vue')},
    {key: 'checkout-register-account', concrete: () => import('./components/checkout/success/RegisterAccountWidget.vue')},
    {key: 'physical-stores-search', concrete: () => import('./components/search/PhysicalStores.vue')},
    {key: 'store-locator', concrete: () => import('./components/search/StoreLocator.vue')},
    {key: 'newsletter-signup', concrete: () => import('./components/default/NewsletterSignUp.vue')},

    {key: 'search-form', concrete: () => import('./components/search/SearchForm.vue')},
    {key: 'search-result-suggested-filters', concrete: () => import('./components/search/results/SearchResultSuggestedFilters.vue')},
    {key: 'search-result-suggested-categories', concrete: () => import('./components/search/results/SearchResultSuggestedCategories.vue')},
    {key: 'search-result-suggested-products', concrete: () => import('./components/search/results/SearchResultSuggestedProducts.vue')},
    {key: 'search-result-suggested-keywords', concrete: () => import('./components/search/results/SearchResultSuggestedKeywords.vue')},
    {key: 'search-result-suggested-content', concrete: () => import('./components/search/results/SearchResultSuggestedContent.vue')},

    {key: 'catalog-product-add-to-cart', concrete: () => import('./components/catalog/product/ProductAddToCart.vue')},
    {key: 'catalog-product-data-layer', concrete: () => import('./components/catalog/product/ProductDatalayer.vue')},
    {key: 'catalog-product-bundle-add-to-cart', concrete: () => import('./components/catalog/product/ProductBundleAddToCart.vue')},
    {key: 'product-in-stock', concrete: () => import('./components/catalog/product/ProductInStock.vue')},
    {key: 'catalog-product-reviews-form', concrete: () => import('./components/catalog/product/ProductReviewsForm.vue')},
    {key: 'checkout-address-autocomplete', concrete: () => import('../../../../../vendor/flashpoint/solar-postcode-api/resources/components/address/AddressAutocomplete.vue')},
    {key: 'customer-address-autocomplete', concrete: () => import('./components/customer/AddressAutocomplete.vue')},
    {key: 'catalog-product-count-down', concrete: () => import('./components/catalog/product/ProductCountDown.vue')},

    {key: 'guided-selling-category-view', concrete: () => import('./../../../../../vendor/happy-horizon/tweakwise-guided-selling/resources/resources/assets/js/components/GuidedSellingCategoryView.vue')},
    {key: 'catalog-page-builder-view', concrete: () => import('./views/catalog/CatalogPageBuilderView.vue')},
    {key: 'catalog-search-view', concrete: () => import('./views/catalog/CatalogSearchView.vue')},
    {key: 'catalog-product-list-banner', concrete: () => import('./components/catalog/ProductListBanner.vue')},
    {key: 'catalog-product-list', concrete: () => import('./components/catalog/ProductList.vue')},
    {key: 'catalog-product-list-item', concrete: () => import('./components/catalog/ProductListItem.vue')},
    {key: 'catalog-category-view', concrete: () => import('./views/catalog/CatalogCategoryView.vue')},
    {key: 'category-content', concrete: require('./components/catalog/CategoryContent').default},
    {key: 'category-content-bottom', concrete: () => import('./components/catalog/CategoryContentBottom.vue')},
    {key: 'catalog-product-list', concrete: () => import('./components/catalog/ProductList.vue')},
    {key: 'catalog-product-list-item', concrete: () => import('./components/catalog/ProductListItem.vue')},
    {key: 'catalog-product-list-toolbar', concrete: () => import('./components/catalog/toolbar/ProductListToolbar.vue')},
    {key: 'catalog-product-list-toolbar-paginator', concrete: () => import('./components/catalog/toolbar/ProductListToolbarPaginator.vue')},
    {key: 'catalog-product-list-toolbar-sort-select', concrete: () => import('./components/catalog/toolbar/ProductListToolbarSortSelect.vue')},
    {key: 'catalog-product-list-toolbar-size-select', concrete: () => import('./components/catalog/toolbar/ProductListToolbarSizeSelect.vue')},
    {key: 'catalog-product-list-toolbar-active-filters', concrete: () => import('./components/catalog/toolbar/ProductListToolbarActiveFilters.vue')},
    {key: 'catalog-product-filter-list', concrete: () => import('./components/catalog/ProductFilterList.vue'),},
    {key: 'catalog-product-store-stock', concrete: () => import('./components/catalog/product/ProductStoreStock.vue'),},
    {key: 'catalog-product-list-attribute-filter', concrete: () => import('./components/catalog/filter/AttributeFilter.vue'),},
    {key: 'catalog-product-list-swatch-filter', concrete: () => import('./components/catalog/filter/SwatchFilter.vue'),},
    {key: 'catalog-product-list-price-filter', concrete: () => import('./components/catalog/filter/PriceFilter.vue'),},
    {key: 'checkout-cart-header', concrete: () => import('./components/checkout/header/CartHeader.vue')},
    {key: 'catalog-product-add-to-compare', concrete: () => import('./components/catalog/product/ProductAddToCompare.vue')},
    {key: 'catalog-product-services', concrete: () => import('./components/catalog/product/ProductServices.vue')},
    {key: 'service-item', concrete: () => import('./components/catalog/product/services/ServiceItem.vue')},
    {key: 'service-item-input', concrete: () => import('./components/catalog/product/services/ServiceItemInput.vue')},

    // Cart components
    {key: 'cart', concrete: () => import('./components/cart/Cart.vue')},
    {key: 'cart-items-list', concrete: () => import('./components/cart/items/List.vue')},
    {key: 'cart-items-list-item', concrete: () => import('./components/cart/items/list/Item.vue')},
    {key: 'cart-totals', concrete: () => import('./components/cart/Totals.vue')},
    {key: 'cart-totals-list', concrete: () => import('./components/cart/totals/List.vue')},
    {key: 'cart-totals-list-segment', concrete: () => import('./components/cart/totals/list/Segment.vue')},

    // Checkout components
    {key: 'checkout-cart-header', concrete: () => import('./components/checkout/header/CartHeader.vue')},
    {key: 'checkout-summary-totals-segment', concrete: () => import('./components/checkout/summary/totals/Segment.vue')},
    {key: 'checkout-checkout', concrete: () => import('./components/checkout/Checkout.vue')},
    {key: 'checkout-cart-item', concrete: () => import('./components/checkout/summary/CheckoutCartItem.vue')},
    {key: 'checkout-cart-bundle', concrete: () => import('./components/checkout/summary/CheckoutCartBundle.vue')},
    {key: 'checkout-service-item', concrete: () => import('./components/checkout/summary/services/ServiceItem.vue')},
    {key: 'checkout-summary-personal-address', concrete: () => import('./components/checkout/summary/personal/Address.vue')},

    {key: 'catalog-product-compare', concrete: () => import('./components/catalog/compare/ProductCompare.vue')},
    {key: 'catalog-product-compare-product-info', concrete: () => import('./components/catalog/compare/CompareProductInfo.vue')},
    {key: 'success-datalayer', concrete: () => import('./components/checkout/success/SuccessDatalayer.vue')},
    {key: 'footer-reviews', concrete: () => import('./components/default/FooterReviews.vue')},
    {key: 'route-planner', concrete: () => import('./components/stores/routePlanner.vue')},
    {key: 'store-search', concrete: () => import('./components/stores/storeSearch.vue')},
    {key: 'store-locator', concrete: () => import('./components/stores/StoreLocator.vue')},
    {key: 'lazy-load', concrete: () => import('./components/LazyLoad.vue')},

    //Wishlist
    {key: 'header-wishlist', concrete: () => import('./components/default/HeaderWishlist.vue')},
    {key: 'customer-wishlist', concrete: () => import('./components/customer/CustomerWishlist.vue')},
    {key: 'product-wishlist-toggle', concrete: () => import('./components/catalog/ProductWishlistToggle.vue')},
]);

import nl_NL from './i18n/nl_NL';

import {localize} from 'vee-validate';
import nl from 'vee-validate/dist/locale/nl.json';

localize({
    nl
});

localize('nl');

const messages = {
    nl_NL
};

const i18n = new VueI18n({
    locale: window.Locale,
    fallbackLocale: 'nl_NL',
    formatFallbackMessages: true,
    silentTranslationWarn: true,
    messages
});

new Vue({
    store,
    i18n,
    components: ServiceContainer().getComponents(),
    data: {
        csrfToken: window.csrfToken
    }
}).$mount('#solar-app');

import {createAddressFromSource, CustomerAddress, CustomerBillingAddress, CustomerShippingAddress, emptyAddressByCountryId, flattenRegionForAddress} from "../../../shared/helpers/quote/address";
import Environment from "@flashpointbv/solar-ui-environment";
import {Address} from "node:cluster";

type StoreState = {
  billingAddress: null|object,
  shippingAddress: null|object,
  shippingIsSameAsBilling: boolean,
};

const initialState = (): StoreState => {
  return {
    billingAddress: null,
    shippingAddress: null,
    shippingIsSameAsBilling: true,
  };
};

const getters = {
  hasBillingAddress: (state, getters): boolean => Object.keys(getters.billingAddress).length > 0,
  hasShippingAddress: (state, getters): boolean => Object.keys(getters.shippingAddress).length > 0,
  billingAddress: (state): CustomerBillingAddress => state.shippingIsSameAsBilling ? {...state.shippingAddress, same_as_billing: 0} : state.billingAddress,
  shippingAddress: (state): CustomerShippingAddress => state.shippingAddress,
  getAddressData: (state, getters, rootState): CustomerAddress[] => {
    let [billingAddress, shippingAddress] =  rootState['GlobalData']['isLoggedIn']
      ? getters.convertAddressData
      : [getters.billingAddress, getters.shippingAddress];

    if (Object.keys(billingAddress).length < 1) {
      billingAddress = emptyAddressByCountryId(Environment().get('checkout_default_country'));
    }

    if (Object.keys(shippingAddress).length < 1) {
      shippingAddress = emptyAddressByCountryId(Environment().get('checkout_default_country'));
    }

    return [billingAddress, shippingAddress];
  },
  convertAddressData: (state): CustomerAddress[] => {
    const billingAddress: CustomerBillingAddress = createAddressFromSource({...state.billingAddress});
    const shippingAddress: CustomerShippingAddress = createAddressFromSource({...state.shippingAddress});

    if (state.shippingIsSameAsBilling) {
      billingAddress['save_in_address_book'] = null
    }

    return [billingAddress, shippingAddress]
  },
};

const mutations = {
  SET_BILLING_ADDRESS(state, address) {
    state.billingAddress = {...address, email: address?.email || state.billingAddress?.email || null}
  },
  SET_SHIPPING_ADDRESS(state, address) {
    state.shippingAddress = {...address, email: address?.email || state.shippingAddress?.email || null}
  },
  SET_SHIPPING_IS_SAME_AS_BILLING(state, status: boolean): void {
    state.shippingIsSameAsBilling = status;
  },
};

const actions = {
  setBillingAddress({commit}, address: Address): void {
    commit('SET_BILLING_ADDRESS', flattenRegionForAddress(address))
  },
  setShippingAddress({commit}, address: Address): void {
    commit('SET_SHIPPING_ADDRESS', flattenRegionForAddress(address))
  },
  toggleShippingIsSameAsBilling({ state, commit }) {
    commit('SET_SHIPPING_IS_SAME_AS_BILLING', !state.shippingIsSameAsBilling);
  },
  async postcodeLookup({}, address): Promise<any> {
    let formatedAddition = (address['houseNrAdd'] || '').replace(/[\s-]/g, '').toUpperCase();
    const { data } = await this.$solarClient.get(`/api/address/autocomplete/details/${address['postcode']}/${address['houseNr']}/${formatedAddition}`);

    return data;
  },
};

const state: StoreState = initialState();

const CheckoutAddress = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default CheckoutAddress;
